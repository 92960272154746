import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { image } from "../utils/images";
import { Container } from "../components/Container";
import { page_data } from "../utils/utils";
import Button from "../components/Buttons/Button";

const Section5 = () => {
  let { section5 } = page_data;
  const rocketScroll = useRef();
  const [percentage_change, setpercentage_change] = useState(0);

  const [page_value, setpage_value] = useState({
    travel_distance: 0,
    height: 0,
    page_height: 0,
    top: 59,
  });
  // useEffect(() => {
  //   const observer = new IntersectionObserver((entries) => {
  //     const entry = entries[0];
  //     console.log(entry);
  //   });
  //   observer.observe(rocketScroll.current);
  // }, []);
  // const windowOnScroll = () => {
  //   // let [offset, height] = [window.pageYOffset - 72, window.innerHeight];
  //   // console.log(
  //   //     offset, height
  //   // );
  //   // if (offset < 0) {
  //   //   setpercentage_change(0);
  //   //   return;
  //   // }
  //   // let temp_percentage = (offset * 100) / height;
  //   // setpercentage_change(temp_percentage <= 0 ? 0 : temp_percentage.toFixed(2));
  //   let rocketBoundonScroll = rocketScroll.current.getBoundingClientRect();
  //   setpage_value((prev) => ({
  //     ...prev,
  //     top:
  //       prev.page_height / 2 + prev.travel_distance >= rocketBoundonScroll.top
  //         ? (61 / prev.travel_distance) *
  //           (prev.page_height / 2 +
  //             prev.travel_distance -
  //             rocketBoundonScroll.top)
  //         : 59,
  //     // travel_distance: rocketBoundonScroll.height,
  //     // height: rocketBoundonScroll.height,

  //     // page_height: window.innerHeight,
  //   }));
  // };
  // useLayoutEffect(() => {
  //   let rocketBound = rocketScroll.current.getBoundingClientRect();
  //   // console.log(rocketBound);
  //   window.addEventListener("scroll", windowOnScroll);
  //   let tempData = {
  //     height: rocketBound.height,
  //     page_height: window.innerHeight,
  //   };
  //   setpage_value((prev) => ({
  //     ...prev,
  //     travel_distance: tempData.page_height / 2 - tempData.height,
  //     ...tempData,
  //   }));
  // }, []);
  // console.log(page_value.top);

  return (
    <>
      <div className="section5">
        <Container>
          <div className="main2_section5">
            <img
              className="show_desktop"
              src="https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/Group%20825.svg"
            />
            <img
              className="show_mobile"
              src="https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/Group%20826.svg"
            />
            {/* <div className="inner_section5" ref={rocketScroll}>
            <div className="section5_title_mobile">{section5.title}</div>
            <div className="section5_box">
                <div className="section5_title">{section5.title}</div>
                <div className="section5_desc">{section5.desc}</div>
                <div className="rocket_mobile">{image.rocketshoot}</div>
              </div>
            <div
                className="rocket"
                // style={{
                //   top: `${
                //     page_value.top > 58
                //       ? 58
                //       : page_value.top < 1
                //       ? 58
                //       : 58 - page_value.top
                //   }%`,
                //   // (baarah.baarah_txt.top <= 64 && baarah.baarah_txt.top >= 1
                //   //   ? baarah.baarah_txt.top
                //   //   : baarah.baarah_txt.top <= 1
                //   //   ? 1
                //   //   : 58) + "%",
                // }}
              >
                {image.rocketshoot}
              </div>
            </div> */}
          </div>
          <Button custclass="section5_btn" txt={section5?.btn} />
        </Container>
      </div>
    </>
  );
};

export default Section5;
