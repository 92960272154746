import React from "react";
import { Container } from "../components/Container";

export const NewSection = () => {
  return (
    <div className="new_section_container">
      <Container>
        <div className="new_section_title">Frameworks we use</div>

        <div className="new_section_box">
          <img
            className="box_floter"
            src="https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/image%20219.svg"
          />

          <img
            className="show_desktop"
            src="https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/Group%20782.svg"
          />
          <img
            className="show_mobile"
            src="https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/Group%20810.svg"
          />
        </div>
      </Container>
    </div>
  );
};
