import React, { useRef, useState } from "react";
import { Container } from "../components/Container";
import { page_data } from "../utils/utils";
import Slider from "react-slick";

const Section7 = () => {
  let { section7 } = page_data;
  const slideTo9 = useRef();
  const [state, setState] = useState(0);
  var settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 10000,
    swipeToSlide: true,
    arrows: false,
    // cssEase: ""
    afterChange: (a) => setState(a),
  };
  return (
    <>
      <div className="section7" id="how-it-works">
        <Container>
          <div className="inner_section7">
            <img src="https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/Group%20783.svg" />
            {/* <div className="section7_title">{section7.title}</div>
            <div className="section7_items">
              {section7.item.map((el, i) => (
                <div key={i} className="items">
                  <div className="item_image">{el.img}</div>
                  <div className="items_name">{el.name}</div>
                  <div className="item_translate">{el.translate}</div>
                  <div className="item_desc">{el.desc}</div>
                </div>
              ))}
            </div> */}
          </div>
        </Container>

        <div className="inner_section7_mobile">
          <div className="section7_title_mobile">Your journey</div>
          <Slider ref={slideTo9} {...settings}>
            {section7.item.map((el, i) => (
              <Container key={i}>
                <div className="items_mob">
                  <div className="item_mobile_header">
                    <div className={`item_image_mob_${i + 1}`}>{el.img}</div>
                    <div className="item_mobile_name_translate">
                      <div className="items_name_mob">{el.name}</div>
                      <div className="item_translate_mob">{el.translate}</div>
                    </div>
                  </div>
                  <div className="item_desc_data_mob">
                    <div className="item_desc_mob">{el.desc}</div>
                    <div className="item_image_mob">
                      <img src={el?.img2} />
                    </div>
                  </div>
                </div>
              </Container>
            ))}
          </Slider>
          {/* <div className="dots_collection">
            {section7.item.map((el, i) => (
              <div
                key={i}
                className={"dots " + (state === i && " activedots")}
                onClick={() => {
                  setState(i);
                  slideTo9.current.slickGoTo(i);
                }}
              ></div>
            ))}
          </div> */}
          <div className="arraow_collection">
            <div
              className={"arraow" + (state + 1 == 1 ? " opas" : "")}
              onClick={() => {
                slideTo9.current.slickPrev();
              }}
            >
              <svg
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.41454 0.702991C5.54729 0.700529 5.67752 0.739388 5.78721 0.814191C5.8969 0.888993 5.98063 0.99604 6.02681 1.12052C6.07299 1.245 6.07934 1.38075 6.04498 1.509C6.01062 1.63724 5.93725 1.75164 5.83502 1.83635L1.92262 5.18569L5.83744 8.53503C5.90896 8.58717 5.96889 8.65356 6.01346 8.73003C6.05803 8.80649 6.08627 8.89137 6.09639 8.97929C6.10651 9.06722 6.09829 9.15629 6.07226 9.24088C6.04622 9.32547 6.00294 9.40374 5.94513 9.47077C5.88733 9.53779 5.81625 9.5921 5.7364 9.63027C5.65655 9.66844 5.56966 9.68965 5.4812 9.69255C5.39274 9.69546 5.30464 9.67999 5.22246 9.64713C5.14028 9.61427 5.0658 9.56474 5.00373 9.50165L0.528274 5.67625C0.457891 5.61613 0.401376 5.54147 0.362625 5.45741C0.323874 5.37334 0.303807 5.28188 0.303807 5.18932C0.303807 5.09675 0.323874 5.00529 0.362625 4.92123C0.401376 4.83716 0.457891 4.7625 0.528274 4.70238L5.00373 0.8649C5.11714 0.763932 5.26274 0.706549 5.41454 0.702991Z"
                  fill="black"
                />
              </svg>
            </div>
            <div className="singleline"></div>
            <div
              className={
                "arraow" + (state + 1 == section7.item.length ? " opas" : "")
              }
              onClick={() => {
                slideTo9.current.slickNext();
              }}
            >
              <svg
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.58741 0.702991C1.45467 0.700529 1.32444 0.739388 1.21475 0.814191C1.10506 0.888993 1.02133 0.99604 0.975144 1.12052C0.928962 1.245 0.922612 1.38075 0.956972 1.509C0.991332 1.63724 1.0647 1.75164 1.16693 1.83635L5.07933 5.18569L1.16452 8.53503C1.093 8.58717 1.03306 8.65356 0.988492 8.73003C0.943922 8.80649 0.915686 8.89137 0.905567 8.97929C0.895447 9.06722 0.903664 9.15629 0.929697 9.24088C0.955731 9.32547 0.999016 9.40374 1.05682 9.47077C1.11463 9.53779 1.1857 9.5921 1.26555 9.63027C1.3454 9.66844 1.4323 9.68965 1.52076 9.69255C1.60921 9.69546 1.69731 9.67999 1.77949 9.64713C1.86167 9.61427 1.93615 9.56474 1.99823 9.50165L6.47368 5.67625C6.54406 5.61613 6.60058 5.54147 6.63933 5.45741C6.67808 5.37334 6.69815 5.28188 6.69815 5.18932C6.69815 5.09675 6.67808 5.00529 6.63933 4.92123C6.60058 4.83716 6.54406 4.7625 6.47368 4.70238L1.99823 0.8649C1.88482 0.763932 1.73922 0.706549 1.58741 0.702991Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section7;
