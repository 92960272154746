import logo from "./logo.svg";
import "./style/main.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { Navbar } from "./components/Navbar";
import { Section1 } from "./sections/Section1";

import Section2 from "./sections/Section2";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Section3 from "./sections/Section3";
import Section4 from "./sections/Section4";
import Section5 from "./sections/Section5";
import { Footer } from "./components/Footer";
import Section6 from "./sections/Section6";
import Section7 from "./sections/Section7";
import Section8 from "./sections/Section8";
import Section9 from "./sections/Section9";
import Section11 from "./sections/Section11";
import { Section10 } from "./sections/Section10";
import { Section1_1 } from "./sections/Section1_1";
import { NewSection } from "./sections/NewSection";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  const [navbar_active_section, setnavbar_active_section] = useState("");
  return (
    <div className="Landing">
      <Navbar {...{ navbar_active_section, setnavbar_active_section }} />
      <Section1 />
      <Section1_1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <NewSection />
      <Section7 />
      <Section8 />
      <Section9 />
      <Section10 />
      <Section11 />
      {/* <div data-aos="fade-up" data-aos-anchor-placement="center-center">
        123
      </div> */}
      <Footer />
      {/* 
      <div className="Fw300">Dhiraj</div>
      <div className="Fw400">Dhiraj</div>
      <div className="Fw500">Dhiraj</div>
      <div className="Fw600">Dhiraj</div>
      <div className="Fw700">Dhiraj</div>
      <div className="Fw800">Dhiraj</div>
      <div className="test" >Dhiraj</div> */}
      {/* <div className="Fw300">Dhiraj</div> */}
    </div>
  );
}

export default App;
