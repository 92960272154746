import React, { useState } from "react";
import { Container } from "../components/Container";
import { image } from "../utils/images";
import { BookACall } from "../components/Buttons/BookACall";
import img_placeholder1 from "../utils/Images/Project-Baarah-Promo.jpg";
import Video from "../utils/Video/Video";
export const Section1_1 = () => {
  const [url, seturl] = useState(0);
  return (
    <div className="section1_1">
      <Container cust_class="section1_1">
        <div className="desktop">
          <div className="txt">
            Discover purposeful apps tackling real-life challenges for your
            goals!
          </div>
          <div className="svg_group">
            {
              <Img
                {...{
                  seturl: (url) => {
                    window.open(
                      [
                        "",
                        "https://networkingninja.glide.page/",
                        "https://www.arva.health/",
                        "https://app.juliettecleaners.com/",
                      ][url]
                    );
                  },
                }}
              />
            }
          </div>
          {url ? (
            <div className="show_app">
              <div className="header">
                <div
                  className="book-a-call"
                  onClick={() => {
                    seturl(0);
                  }}
                >
                  Close
                </div>
              </div>
              <iframe
                src={
                  [
                    "",
                    "https://networkingninja.glide.page/",
                    "https://www.arva.health/",
                    "",
                  ][url]
                }
              />
            </div>
          ) : (
            ""
          )}
        </div>
        {/* <div className="mobile"></div> */}
      </Container>
    </div>
  );
};
const Img = ({ seturl }) => {
  return (
    <>
      {/* <div
        onClick={() => seturl(2)}
        width="183"
        height="202"
        viewBox="0 0 183 202"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
      > */}
      <img
        onClick={() => seturl(1)}
        src="https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/networking%20ninja%20logo.svg"
      />
      <img
        onClick={() => seturl(2)}
        src=" https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/arva%20logo.svg"
      />
      <img
        onClick={() => seturl(3)}
        src="https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/juliette%20logo.svg"
      />
      {/* </div> */}
    </>
  );
};
